import React, { useEffect, useState } from 'react';
import Header from '../component/Header/Header';
import TopPage from '../component/TopPage/TopPage';
import Footer from '../component/footer/Footer';
import ArticleContainer from '../component/ArticleContainer/ArticleContainer';
import axios from 'axios';
import HeaderMobile from '../component/Header/HeaderMobile';
import api from "../api"
import { Helmet } from 'react-helmet';
const AllNewsPage = () => {
    const urlApi = process.env.REACT_APP_BASE_URL;
    const[admin,setAdmin] = useState();
    useEffect(()=>{
        
        api.get(`/getAdmin`)
        .then((data)=>{
            console.log(data);
            setAdmin(data.data);
        })
        .catch(error=>{
            console.log(error);
        })
        
    
    },[]);
const HandleButtonCreate=(e)=>{
    api.post("/addArticle")
    .then((result)=>{
        window.location.reload();
    }).catch((error)=>{
        console.log(error);
    });
}
    return (
        <div id='Sections'>
            <Helmet>
                <title>Tout les articles</title>
                <meta name="description" content="Consultez tout les anciens articles de Nabledev" />
            </Helmet>
            <Header></Header>
            <HeaderMobile></HeaderMobile>
            <TopPage text={'NEWS'}></TopPage>
            {admin === true ? (
                <button onClick={HandleButtonCreate} type="button">Creer nouvel article</button>

            ):(
                <span></span>
            )}
            <div id='allnewsdivpres'>
            <h1 className='shadowedText'>Tout mes articles</h1>
            <p  className='shadowedText'>(pour un peu de lecture...)</p>
            </div>
            <div style={{marginTop:"60px"}}></div>
            {admin != undefined ? (
                <ArticleContainer onlypublic={!admin}></ArticleContainer>
            ):(
                <span></span>
            )}
            
            <div style={{marginTop:"220px"}}></div>
            <Footer></Footer>
            
        </div>
    );
};

export default AllNewsPage;