import React from 'react';
import Header from '../component/Header/Header';
import HeaderMobile from '../component/Header/HeaderMobile';
import TopPage from '../component/TopPage/TopPage';
import Footer from '../component/footer/Footer';
import { Helmet } from 'react-helmet';

const UnfoundedArticle = () => {
    return (
        <div id='Sections'>
            <Helmet>
            <meta name="robots" content="noindex" />
                <title>erreur 404</title>
                <meta name="description" content="Page d'erreur" />
            </Helmet>
            <Header></Header>
            <HeaderMobile></HeaderMobile>
            <TopPage text={"NEWS"}></TopPage>
            <div style={{marginTop:"150px"}}></div>
            <span style={{fontSize:"42px"}}>ERREUR 404 , la page recherchée semble indisponible</span>
            <div style={{marginTop:"200px"}}></div>
            <Footer></Footer>
        </div>
    );
};

export default UnfoundedArticle;