import React, { useState } from 'react';
import axios, { Axios } from 'axios';
import { useEffect } from 'react';
import Article from '../Article/Article';
import api from "../../api"
const ArticleContainer = ({limit,onlypublic}) => {
    const [articles,setArticles] = useState();

    const urlApi = process.env.REACT_APP_BASE_URL;

    useEffect(()=>{
        console.log(onlypublic);
        api.get("/all").then((data)=>{
            console.log(data.data.rows);
            
            if(articles != undefined){
                if(limit === undefined || limit > articles.length){
                    limit = articles.length;
                    console.log("++++"+limit);
                }
            }
            if(!onlypublic){
                setArticles(data.data.rows);
            }
            else{
                let articlePublics = [];

                for (let index = 0; index < data.data.rows.length; index++) {
                    const element = data.data.rows[index];
                    if(element.public === true){
                        articlePublics.push(element);
                        if(articlePublics.length === limit){
                            break;
                        }
                    }
                    
                }
                setArticles(articlePublics);
            }
            
        })
        .catch(error=>{
            console.log(error);
        })
    },[]);
    return (
        <div style={{display:"flex",flexDirection:"column",justifyContent:"center",alignSelf:"center",minWidth:"350px",width:"60%"}}>

          
            {articles !== undefined ? (
  
                articles.slice(0,limit).map((art) => (
                <Article key={art.id} nom={art.titre} resume={art.resume} image={art.image} id={art.id} visible={art.public}></Article>
            ))
            ) : (
                <h1></h1>
            )}
        </div>
    );
};

export default ArticleContainer;