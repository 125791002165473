import axios, { Axios } from 'axios';
import React, { useState } from 'react';
import { useEffect } from 'react';
import Article from '../component/Article/Article';
import Header from '../component/Header/Header';
import TopPage from '../component/TopPage/TopPage';
import Presentation from '../component/presentation/Presentation';
import Footer from '../component/footer/Footer';
import { NavLink } from 'react-router-dom';
import ArticleContainer from '../component/ArticleContainer/ArticleContainer';
import HeaderMobile from '../component/Header/HeaderMobile';
import { Helmet } from 'react-helmet';




const HomePage = () => {

  
    
    return (
      
        <div id='Sections'>
           <Helmet>
                <title>Nabledev Newsletter</title>
                <meta name="description" content="Consultez mes anciens articles ou inscrivez vous a la newsletter de Nabledev pour ne plus en loupez un seul" />
            </Helmet>
          <Header></Header>
          <HeaderMobile></HeaderMobile>
          <TopPage text={"ACCUEIL"}></TopPage>
          <div style={{marginTop:"100px"}}></div>

          <Presentation></Presentation>

          <div style={{marginTop:"100px"}}></div>
        <ArticleContainer limit={3} onlypublic={true}></ArticleContainer> 
      <div style={{marginTop:"40px"}}></div>
      <NavLink style={{justifyContent:"center",display:"flex"}} to={"/news"}>voir plus...</NavLink>
      <div style={{marginTop:"100px"}}></div>
      <Footer></Footer>
    </div>
    );
};

export default HomePage;