import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ButtonAdmin from '../component/buttonAdmin/ButtonAdmin';
import Header from '../component/Header/Header';
import TopPage from '../component/TopPage/TopPage';
import Footer from '../component/footer/Footer';
import HeaderMobile from '../component/Header/HeaderMobile';
import api from "../api"
import { Helmet } from 'react-helmet';

const ArticlePage = () => {
    const InitialArticleData = {
        titre:"",
        contenu:"",
        resume:"",
        image:"",
        public:false,
        subscribetosee:false
    }
    const {id} = useParams();
    const[admin,setAdmin] = useState();
    const[editMode,setEditMode] = useState(false);
    const[textfirstround,setTextFirstRound] = useState("");
    const[articleData,setArticleData] = useState(InitialArticleData);
    console.log(id);
    const usenav = useNavigate();
    const urlApi = process.env.REACT_APP_BASE_URL;
    let balise;
    
    
    
    useEffect(()=>{
        api.get(`/articleById`,{
            params:{
                id:id
            }
        })
        .then((data)=>{
            const article = data.data.rows[0];
            setArticleData({...articleData,titre:article.titre,contenu:article.contenu,resume:article.resume,image:article.image,public:article.public,subscribetosee:article.subscribetosee})
            setTextFirstRound(article.contenu);
            api.get(`/getAdmin`,{
                withCredentials:true
            })
            .then((data)=>{
                
                setAdmin(data.data);
                setEditMode(data.data);
                console.log(article.public);
                console.log(data.data);
                if(article.public === false && data.data === false){
                    usenav("/404");
                }
            })
            
        })
        .catch(error=>{
            usenav("/404");
        })
        
        
        
    
    },[])

    const handleCheckBoxEdit = ()=>{
        setEditMode(!editMode)
        
    }

    useEffect(()=>{
        balise = document.getElementById("textAfficher");
        if(balise !== null &&articleData.contenu !== ""){
            afficherTexte(articleData.contenu);
        }
        

    },[articleData]);

    useEffect(()=>{
        balise = document.getElementById("textAfficher");
        if(balise !== null &&articleData.contenu !== ""){
            afficherTexte(articleData.contenu);
        }
        

    },[editMode]);

    const submitSave=(e)=>{
        e.preventDefault();
        api.put(`/setArticle/${id}`,articleData)
        .catch(error=>{
            console.log(error);
        })

    }

    const afficherTexte = (texte)=>{
        const textAAfficher = texte;
        console.log(":"+textAAfficher);
     balise.innerHTML =textAAfficher;
    }

    const HandleSendMail = (e)=>{
        e.preventDefault();
        api.post(`/sendmailAll`,
        {subject:articleData.titre,
        html:articleData.contenu})
        .then(result=>{
            console.log(result);
        })
        .catch(error=>{
            console.log(error);
        })

        
    }

 
    return (
        <div id='Sections'>
            <Helmet>
                <title>{articleData.titre}</title>
                <meta name="description" content={articleData.resume} />
            </Helmet>
            <Header></Header>
            <HeaderMobile></HeaderMobile>
            <TopPage text={'NEWS'}></TopPage>
            {admin === true ? (
                <div>
                    <form > 
                        <label htmlFor="admin">Edit mode</label>
                        <input checked={editMode} onChange={handleCheckBoxEdit} type="checkbox" name="admin" id="" />
                        <button onClick={submitSave} type="button">save</button>
                        <button onClick={HandleSendMail}>Envoyer mail</button>
                    </form>
                    
                </div>
            ):
            (
                <span></span>
            )}
            
            <div style={{marginTop:"50px"}}></div>
            {editMode === true ?(
                <div>
                    <form style={{display:"flex",flexDirection:"row"}}>
                        <label htmlFor="public">public</label>
                        <input checked={articleData.public} onChange={(e)=> setArticleData({...articleData,public: !articleData.public})} type="checkbox" name="public" id="" />
                        <label htmlFor="subs">subs</label>
                        <input checked={articleData.subscribetosee} onChange={(e)=> setArticleData({...articleData,subscribetosee: !articleData.subscribetosee})} type="checkbox" name="subs" id="" />
                    </form>
                    <form style={{display:"flex",flexDirection:"column"}}>
                        <input value={articleData.titre} type="text" name="" id="" 
                        onChange={(e)=> setArticleData({...articleData,titre:e.target.value})}/>
                        <input value={articleData.resume} type="text" name="" id="" 
                        onChange={(e)=> setArticleData({...articleData,resume:e.target.value})}/>
                        <textarea value={articleData.contenu} name="" id="" cols="30" rows="10"
                        onChange={(e)=> setArticleData({...articleData,contenu:e.target.value})}></textarea>
                    </form>
                </div>
            ):(
                <div id='presArticleTop'>
                
                <div id='presArticle' >
                    <div id='presArticleText'>
                        <h1 className='shadowedText' >{articleData.titre}</h1>
                        <h2 className='shadowedText' >{articleData.resume}</h2>
                        
                    </div>
                    
                    <img style={{width:"250px",height:"250px",borderRadius:"44px"}} src={`/${articleData.image}`} alt="" />
                </div>
                <div style={{marginTop:"80px"}}></div>
                <div id='articleText'>
                    <p id='textAfficher'></p>
                </div>
                
                 
                </div>
            )}
            <div style={{marginTop:"160px"}}></div>

            <Footer></Footer>
            
            
        </div>
    );
};

export default ArticlePage;