import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const Header = () => {
    const useLoc = useLocation();
    const [mobileVersion,setMobileVersion] = useState();

    window.addEventListener("resize",(e)=>{
        if(window.innerWidth < 1160){
            setMobileVersion(true);
        }
        else{
            setMobileVersion(false);
        }
    })

    useEffect(()=>{
        window.scrollTo(0, 0);
        if(window.innerWidth < 1160){
            setMobileVersion(true);
        }
        else{
            setMobileVersion(false);
        }
        
    },[useLoc]);

    const HandleMenuMobile=()=>{
        const menu = document.getElementById("MenuMobile");
        menu.classList.add("showMenuMobile");
    }
    
    return (
        <div id='header'>
            {mobileVersion === false ?(
                <div id='header'>
                     <h1 className='shadowedText'>- NABLEDEV-NewsLetter -</h1>
                    <div id='ContainerPages' className='shadowedText'>
                    <NavLink to="/" className={useLoc.pathname === "/" ? "active" : ""}>Acceuil</NavLink>
                    <NavLink to={"/news"} className={useLoc.pathname === "news" ? "active" : ""}>AllNews</NavLink>
                    <NavLink to={"/about"} className={useLoc.pathname === "/about" ? "active" : ""}>About</NavLink>
                    </div>
                </div>
            ):(
                <div id='menuMobileIMG'>
                    <img onClick={HandleMenuMobile}  src="../Menu.png" alt="" />
                </div>
            )}
           
        </div>
    );
};

export default Header;