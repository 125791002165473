import React from 'react';

const TopPage = ({text}) => {
    return (
        <div id='topPage'>
            <span>{text}</span>
        </div>
    );
};

export default TopPage;