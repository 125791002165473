import React from 'react';
import { NavLink } from 'react-router-dom';

const HeaderMobile = () => {

    const HandleMenuMobile=()=>{
        const menu = document.getElementById("MenuMobile");
        menu.classList.remove("showMenuMobile");
    }
    return (

            <div id='MenuMobile'>
                    <h1 onClick={HandleMenuMobile}>X</h1>
                    <div style={{marginTop:"40px"}}></div>
                    <h2 className='shadowedText'>- NABLEDEV-NewsLetter -</h2>
                    <div style={{marginTop:"40px"}}></div>
                    <NavLink to="/" >Acceuil</NavLink>
                    <NavLink to={"/news"} >AllNews</NavLink>
                    <NavLink to={"/about"} >About</NavLink>
            </div>

    );
};

export default HeaderMobile;