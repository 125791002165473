import React, { useEffect, useState } from 'react';
import Header from '../component/Header/Header';
import TopPage from '../component/TopPage/TopPage';
import Footer from '../component/footer/Footer';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import HeaderMobile from '../component/Header/HeaderMobile';
import api from "../api"
import { Helmet } from 'react-helmet';
const AccountPage = () => {
    const usenav = useNavigate();
    const urlApi = process.env.REACT_APP_BASE_URL;
    const [mail,setMail] = useState();
    useEffect(()=>{
        console.log(mail);
        api.get("/getMail",{
            withCredentials:true
        }).then(result=>{
            setMail(result.data);
        }).catch(error=>{
            console.log(error);
        })
    },[])

    const HandleUnsubscribe=()=>{
        api.delete("/deleteMail",{
            params:{
                mail:mail
            }
           

        }).then(result=>{
            console.log(result);
            api.get("/setCookieMail",{
                withCredentials:true,
                params:{
                    mail:""
                }
            }).then((result)=>{
                usenav("/");
            })
            .catch(error=>{
                console.log(error);
            })
        }).catch(error=>{
            console.log(error);
        })
        console.log("oui");
    }
    return (
        <div id='Sections'>
           <Helmet>
                <title>Ton compte</title>
                <meta name="description" content="Consultez vos informations liées à ma newsletter ici ou désabonnez-vous en toute simplicité." />
            </Helmet>
            <Header></Header>
            <HeaderMobile></HeaderMobile>
            <TopPage text={'COMPTE'}></TopPage>
            {mail === "" ? (
                <div id='Sections'>
                    <div style={{marginTop:"50px"}}></div>
                    <h2 className='shadowedText'>Tu n'es pas encore abonné ! Qu'est-ce que tu attends ? ;)</h2>
                    <div style={{marginTop:"50px"}}></div>
                </div>
            ):(
                <div id='Sections'>
                     <h1 className='shadowedText'>Vos informations</h1>
                    <h2>{mail}</h2>
                    <div style={{marginTop:"150px"}}></div>
                     <span onClick={HandleUnsubscribe}>se desabonner</span>
                </div>
            )}
           
            <div style={{marginTop:"250px"}}></div>
            <Footer></Footer>
        </div>
    );
};

export default AccountPage;