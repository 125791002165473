import { logDOM } from '@testing-library/react';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import api from "../../api"
const Subscribe = () => {
    const usenav = useNavigate();
    const [mail,setMail] = useState("");
    const [mailCookie,setMailCookie] = useState();
    const useLoc = useLocation();
    const [anim,setAnim] = useState(false);
    

    const HandleSubmitSubscribe =(e)=>{
        e.preventDefault();
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if(emailRegex.test(mail)){
            api.post("/addmail",{mail:mail}).then((result)=>{
                api.get("/setCookieMail",{
                    params:{
                        mail:mail
                    }
                }).then((result)=>{
                    console.log(result);
                })
                
                
            }).catch((error)=>{
                api.get("/setCookieMail",{
                    params:{
                        mail:mail
                    }
                }).then((result)=>{
                    console.log(result);
                })
                
            })
            SendMailBienvenue();
            JouerAnim();
    
        }
        
    }

    const SendMailBienvenue=()=>{
        api.post("/sendMailBienvenue",{mail:mail}).then(result=>{
            console.log(result);
        })
        .catch(eror=>{
            console.log(eror);
        })
    }

    useEffect(()=>{
        GetCookieMail();
        console.log(mailCookie);
    },[])
    const GetCookieMail=()=>{
        api.get("/getMail",{
            withCredentials:true
        }).then(result=>{
            if(result.data !=""||result.data != undefined){
                if(useLoc.pathname === "/subscribe" && result.data != ""){
                    usenav("/");
                }
                setMailCookie(result.data);
            }
            
            console.log(result);
        }).catch(error=>{
            console.log(error);
        })
    }

    useEffect(()=>{
        console.log("lalalal");
        const textSubscribed = document.getElementById("subscribedText");
        const doneImage = document.getElementById("doneImage");
        if(textSubscribed && doneImage &&anim){
            doneImage.classList.add("WidthZero");
            textSubscribed.classList.add("colorInvisible");
            setTimeout(()=>{
                doneImage.classList.remove("WidthZero");
                textSubscribed.classList.remove("colorInvisible");
                setTimeout(()=>{
                    if(useLoc.pathname === "/subscribe"){
                        usenav("/");
                    }
                },2000)
            },500)
        }
            
        
    },[mailCookie])
    const JouerAnim=()=>{
        setAnim(true);
        const input = document.getElementById("inputSubscribe")
        const button = document.getElementById("buttonSubscribe")
        input.classList.remove("shadowedText");
        input.classList.add("inputSubscribeDisapear");
        button.classList.remove("shadowedText");
        button.classList.add("inputSubscribeDisapear");
        setTimeout(()=>{
            setMailCookie(mail);
            
           
        },1500)

    }


    return (
        <div id='subscribeDiv'>
            {mailCookie === "" || mailCookie === undefined?(
                <form id='formSubscribe' onSubmit={HandleSubmitSubscribe}>
                <input value={mail} onChange={(e)=>setMail(e.target.value)} placeholder='rentrez votre email...' className='shadowedText' type="email" name="" id="inputSubscribe" />
                <button className='shadowedText' id='buttonSubscribe'>Subscribe</button>
            </form>
            ):(
                <div>
                    <NavLink id='subscribedDiv' to={"/account"}>
                        <img id='doneImage' src="../Done.png" alt="" />
                        <span id='subscribedText'>subscribed</span>
                    </NavLink>
                </div>
            )}
            
        </div>
    );
};

export default Subscribe;