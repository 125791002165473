import axios from 'axios';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL, // Remplacez par l'URL de votre API
  headers: {
    'x-api-key':process.env.REACT_APP_API_KEY,
    'Content-Type': 'application/json'
    //"Access-Control-Allow-Origin": "http://localhost:3000"


    

  },
  withCredentials:true
});

export default instance;