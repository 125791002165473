import React from 'react';

const Presentation = () => {
    return (
        <div id='presentationContainer'>
            <div id='presimagecontainer'>
                <img id='imgpres' src="channelProfil.png" alt="" />
            </div>
            <div id='prestextcontainer'>
                <h2 className='shadowedText'>RETROUVEZ TOUT MES ARTICLES</h2>
                <p id='textpres'>
                Salut, c'est nabledev, et bienvenue dans ma newsletter ! <br />
                Si tu n'es pas abonné, je t'invite à le faire. Il te suffit de renseigner ton mail, c'est totalement gratuit. Ci-dessous, ce sont tous mes posts. 
                Si tu veux les recevoir en avance, tu sais ce qu'il te reste à faire.
                </p>
            </div>
        </div>
    );
};

export default Presentation;