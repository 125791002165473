import React from 'react';
import Header from '../component/Header/Header';
import TopPage from '../component/TopPage/TopPage';
import Footer from '../component/footer/Footer';
import HeaderMobile from '../component/Header/HeaderMobile';
import { Helmet } from 'react-helmet';

const AboutPage = () => {
    return (
        <div id='Sections'>
            <Helmet>
                <title>A propos</title>
                <meta name="description" content="Si tu ne sais pas quels avantages offre ma newsletter, NableDev répond à toutes tes questions !" />
            </Helmet>
            <Header></Header>
            <HeaderMobile></HeaderMobile>
            <TopPage text={'A PROPOS'}></TopPage>
            <div id='AboutTextContainer'>
            <h1 className='shadowedText'>Pourquoi s'abonner ?</h1>
<p>Chaque semaine, du <span className='bold'>nouveau</span> contenu, que ce soit le <span className='bold'>code source</span> de mes projets, des conseils et des plans concrets pour apprendre le code à partir de <span className='bold'>zéro</span> !</p>
<h1 className='shadowedText'>C’est gratuit !</h1>
<p><span className='bold'>Et oui !</span> Tu recevras chaque semaine ma newsletter dans ta <span className='bold'>boîte mail</span>, à laquelle tu peux te désabonner <span className='bold'>n'importe quand.</span></p>
<h1 className='shadowedText'>Rejoins-moi !</h1>
<p>En t'abonnant, tu es sûr d'accéder à mes meilleures informations.</p>
            </div>
            <div style={{marginTop:"200px"}}></div>
            <Footer></Footer>
        </div>
    );
};

export default AboutPage;