import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import api from "../../api"
const Article = ({nom,resume,image,id,date,visible}) => {
    const[admin,setAdmin] = useState();
    const urlApi = process.env.REACT_APP_BASE_URL;
    useEffect(()=>{
        api.get(`/getAdmin`,{
            withCredentials:true
        })
        .then((data)=>{
            setAdmin(data.data);
        })
    
    },[]);

    const HandleSupprButton =() =>{
       const confirm = window.confirm("tu veux vraiment suppr");
       if(confirm){
            console.log('il a confirmer ' + id);
            api.delete('/deleteArticle/'+id)
            .then((data)=>{
                window.location.reload();
            })
            .catch((err)=>{
                console.log(err);
            })
       }
       else{
            console.log('refuser');
       }
    }
    return (
        <div className={visible? "" : "notPublic"} id='article'>
            <NavLink id='articleContainer' to={`/article/${id}`}>
                <div>
                    <h2 id='titreArticle' className='shadowedText'>{nom}</h2>
                    <h3>{resume}</h3>
                    
                </div>
                <div id='imgArticleContainer'>
                    <img id='imgArticle' src={image} alt="" />
                </div>
           
               
            </NavLink>
            {admin === true ? (
                        <button onClick={HandleSupprButton}  type="button">Suppr</button>

                    ):(
                        <span></span>
            )}
        </div>
    );
};

export default Article;