import React, { useEffect, useState } from 'react';
import Subscribe from '../Subscribe/Subscribe';
import { NavLink } from 'react-router-dom';
import axios from 'axios';
import api from "../../api"


const Footer = () => {
    const urlApi = process.env.REACT_APP_BASE_URL;
    const[admin,setAdmin] = useState();
    useEffect(()=>{
        api.get('/getAdmin')
        .then((data)=>{
            setAdmin(data.data);
        })
        .catch(error=>{
            console.log(error);
        })

    },[])


    const handleDeconnect=()=>{
        api.get("/disconnect",{
            withCredentials:true,
            
        }).then((data)=>{
            setAdmin(false);
            window.location.reload();
        })
        
    }
    return (
        <div id='footer'> 
            <Subscribe></Subscribe>
            <div style={{marginTop:"50px"}}></div>
            {admin === true ?(
                <span id='connecionfooterlink' onClick={handleDeconnect}>deconnexion</span>
            ):
            (
                <NavLink id='connecionfooterlink' to={"/connexion"}>connexion</NavLink>
                
            )}
            
        </div>
    );
};

export default Footer;