import React, { useState } from 'react';
import Header from '../component/Header/Header';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import HeaderMobile from '../component/Header/HeaderMobile';
import api from "../api"
import { Helmet } from 'react-helmet';
const ConnexionPage = () => {
    const navigate = useNavigate();
    const urlApi = process.env.REACT_APP_BASE_URL;
    const[login,setLogin] = useState("");
    const[password,setPassword] = useState("");
    const handleSubmitConnexion=(e)=>{
        e.preventDefault();
        
        api.get("/connexion",{
            params:{
                login:login,
                password:password

            }
            
        })
        .then((data)=>{
            navigate("/");
        })
        .catch(error=>{
            console.log(error);
        })
        

    }
    return (
        <div id='Sections'>
             <Helmet>
                <title>Connexion</title>
                <meta name="robots" content="noindex" />
                <meta name="description" content="Page de connexion reservés aux admins" />
            </Helmet>
            <Header></Header>
            <HeaderMobile></HeaderMobile>
            <h1>espace reserver admin</h1>
            <div style={{marginTop:"50px"}}></div>
            <form style={{display:'flex',flexDirection:"column",alignItems:"center"}}  onSubmit={handleSubmitConnexion}>
                <label htmlFor="login">login</label>
                <input  type="text" name="login" id="inputSubscribeConnect" 
                value={login}
                onChange={(e)=> setLogin(e.target.value)}/>
                <div style={{marginTop:"50px"}}></div>
                <label htmlFor="password">password</label>
                <input type="password" name="password" id="inputSubscribeConnect"
                value={password}
                onChange={(e)=> setPassword(e.target.value)} />
                <div style={{marginTop:"100px"}}></div>
                <button id='buttonSubscribeConnect' type="submit">connexion</button>
            </form>
        </div>
    );
};

export default ConnexionPage;