
import SubscribePage from "./page/SubscribePage";
import './component/compenentsstyle.scss'
import './page/AboutPageStyle.scss';
import './page/ConnexionPageStyle.scss';
import './page/ArticlePageStyle.scss';

import HomePage from "./page/HomePage";
import { BrowserRouter, Router, Routes ,Route} from "react-router-dom";
import ArticlePage from "./page/ArticlePage";
import ConnexionPage from "./page/ConnexionPage";
import AccountPage from "./page/AccountPage";
import AboutPage from "./page/AboutPage";
import AllNewsPage from "./page/AllNewsPage";
import UnfoundedArticle from "./page/UnfoundedArticle";

function App() {
  return (
    <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/subscribe" Component={SubscribePage}></Route>
            <Route path="/" Component={HomePage}></Route>
            <Route path="/article/:id" Component={ArticlePage}></Route>
            <Route path="/connexion" Component={ConnexionPage}></Route>
            <Route path="/account" Component={AccountPage}></Route>
            <Route path="/about" Component={AboutPage}></Route>
            <Route path="/news" Component={AllNewsPage}></Route>
            <Route path="/404" Component={UnfoundedArticle}></Route>
            <Route path="/*" Component={UnfoundedArticle}></Route>
          </Routes>
        </BrowserRouter>
    </div>
  );
}

export default App;
