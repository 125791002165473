import React from 'react';
import Subscribe from '../component/Subscribe/Subscribe';
import { NavLink } from 'react-router-dom';
import { Helmet } from 'react-helmet';


const SubscribePage = () => {
    return (
        <div style={{display:"flex",alignContent:"center",textAlign:"center",flexDirection:"column"}}>
            <Helmet>
                <meta name="robots" content="noindex" />
                <title>Abonne toi !</title>
                <meta name="description" content="Cette page permet de s'abonnez" />
            </Helmet>
            <div style={{marginTop:"20px"}}></div>
            <img style={{width:"200px",margin:"auto"}} src="channelProfil.png" alt="" />
            <span style={{marginTop:"32px"}}></span>
            <h1 className='shadowedText'>NABLEDEV-NewsLetter</h1>
            <h2 style={{maxWidth:"800px",margin:'auto',padding:"10px"}} className='shadowedText'>Du nouveau <span className='bold'>contenu</span>  chaque semaine completement <span className='bold'>gratuit</span></h2>
            <span style={{marginTop:"44px"}}></span>
            <Subscribe></Subscribe>
            <div style={{marginTop:"50px"}}></div>
            <NavLink to={"/"}>
            <span style={{fontSize:"16px",color:"#E1E1E1"}} id='nop'>non merci > </span>
            </NavLink>
            <div style={{marginTop:"50px"}}></div>
        </div>
    );
};

export default SubscribePage;